import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IRegistrationForm } from '@models/authentication.model';
import { IUser } from '@models/user.model';
import { AppValidators } from "@validators/validators";
import { Gender } from 'app/core/enums/gender.enum';

@Component({
    selector: 'registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent {

    @Input() isUpdate = false;
    @Input() user: IUser;

    @Output() onSubmit = new EventEmitter<IRegistrationForm>();

    selectedGender: Gender | string = Gender.male;
    showPassword: boolean;
    coachForm: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {

        if (this.isUpdate) {
            this.createUpdateForm();
        } else {
            this.createRegistrationForm();
        }


    }

    createRegistrationForm() {
        this.coachForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            name: ['', Validators.required],
            email: ['', [Validators.required, AppValidators.Email]],
            password: ['', [Validators.required, Validators.minLength(1)]],
            passwordRepeat: ['', [Validators.required, Validators.minLength(1)]]
        }, {
            validator: AppValidators.MustMatch('password', 'passwordRepeat')
        });
    }

    createUpdateForm() {
        this.coachForm = this.formBuilder.group({
            first_name: [this.user.first_name, Validators.required],
            name: [this.user.name, Validators.required],
            address_1: [this.user.address_1, Validators.required],
            zip: [this.user.zip, Validators.required],
            city: [this.user.city, Validators.required],
            email: [this.user.email, [Validators.required, AppValidators.Email]],
        })
    }

    onSubmitClicked(): void {
        if (this.coachForm.valid) {
            this.onSubmit.emit({
                ...this.coachForm.value,
                gender: this.selectedGender
            });
        }
    }

}
