import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IInvitation } from '@models/invitation.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { DEFAULT_AVATAR_URL } from 'app/core/consts/default-avatar.const';
import { InvitationActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invite-block',
  templateUrl: './invite-block.component.html',
  styleUrls: ['./invite-block.component.scss'],
})
export class InviteBlockComponent implements OnInit {
  @Input() invite: IInvitation;
  defaultImage: string = DEFAULT_AVATAR_URL;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService,
    private actions$: Actions,
    private uiService: UIService) { }

  ngOnInit() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(InvitationActions.ACCEPT_INVITE_SUCCESS))
          .subscribe((data: any) => {
            this.router.navigate(['/athlete-info']);
          });
  }

  cancel() {
    let title = this.translate.instant('auth.invite-code.cancel_invite_title');
    let desc = this.translate.instant('auth.invite-code.cancel_invite_desc');
    this.uiService.confirmModal(title, desc)
      .then(data => {
        if (data) {
          this.store.dispatch(new InvitationActions.CancelInviteAction(this.invite?.id));
        }
      })
  }

  accept() {
    let title = this.translate.instant('auth.invite-code.accept_invite_title');
    let desc = this.translate.instant('auth.invite-code.accept_invite_desc');
    this.uiService.confirmModal(title, desc)
      .then(data => {
        if (data) {
          this.store.dispatch(new InvitationActions.AcceptInviteAction(this.invite?.coach?.id, this.invite?.code));
        }
      })
  }
}
