// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content.select-role-view {
  --background: var(--ons-color-primary-background);
}
ion-content.select-role-view main.inner-scroll {
  padding: 10px !important;
}
ion-content.select-role-view ion-row {
  justify-content: center;
}

.button-inner {
  display: flex;
  flex-direction: column;
}

.btnCoach, .btnAthlete {
  width: 45%;
}

.button-card {
  padding: 0;
  height: 150px !important;
  font-size: 18px !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
}
.button-card .btn-card-icon {
  font-size: 35px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/select-role/select-role.page.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AACE;EACE,uBAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,UAAA;EACA,wBAAA;EACA,0BAAA;EACA,4CAAA;AAAF;AACE;EAEE,eAAA;AAAJ","sourcesContent":["ion-content.select-role-view {\n  --background: var(--ons-color-primary-background);\n  main.inner-scroll {\n    padding : 10px !important;\n  }\n\n  ion-row {\n    justify-content: center;\n  }\n}\n\n.button-inner {\n  display: flex;\n  flex-direction: column;\n}\n\n.btnCoach, .btnAthlete {\n  width: 45%;\n}\n\n.button-card {\n  padding: 0;\n  height: 150px !important;\n  font-size: 18px !important;\n  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);\n  .btn-card-icon {\n    // margin-right: 12px;\n    font-size: 35px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
