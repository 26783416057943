// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.input-item {
  color: var(--ons-color-black-2);
  font-size: 16px;
  --padding-start: 0;
  font-size: 16px;
  height: 80px;
  width: 100%;
}
ion-item.input-item ion-icon {
  margin-top: 34px;
  margin-right: 0;
}
ion-item.input-item ion-icon.checkmark {
  color: var(--ons-color-primary);
}
ion-item.input-item ion-label {
  color: var(--ons-color-primary-black);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/components/registration-form/registration-form.component.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;AACJ;AACI;EACE,+BAAA;AACN;AAGE;EACE,qCAAA;AADJ","sourcesContent":["ion-item.input-item {\n  color: var(--ons-color-black-2);\n  font-size: 16px;\n  --padding-start: 0;\n  font-size: 16px;\n  height: 80px;\n  width: 100%;\n\n  ion-icon {\n    margin-top: 34px;\n    margin-right: 0;\n\n    &.checkmark {\n      color: var(--ons-color-primary);\n    }\n  }\n\n  ion-label {\n    color: var(--ons-color-primary-black);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
