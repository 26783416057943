import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { Router } from "@angular/router";
import { IRegistrationForm, UserRole } from "@models/authentication.model";
import { Action, Store } from "@ngrx/store";
import { AppState } from "app/core/store/app.states";
import { AuthActions } from "app/core/store/actions";
import { Observable, Subscription } from "rxjs";
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: "app-register",
  templateUrl: "./registration.page.html",
  styleUrls: ["./registration.page.scss"],
})
export class RegistrationPage implements OnInit, OnDestroy {
  loginSuccess$: Observable<Action>;
  subscription: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private actions$: Actions
  ) {
    this.loginSuccess$ = this.actions$.pipe(ofType(AuthActions.loginSuccess));
  }

  ngOnInit() {
    this.subscription = this.loginSuccess$.subscribe(() => {
      this.router.navigate(['/upload-avatar']);
    });
  }

  onRegister(formData: IRegistrationForm): void {
    this.auth
      .register(formData, UserRole.coach)
      .then(() => {
        this.store.dispatch(
          AuthActions.login({
            credentials: { email: formData.email, password: formData.password },
          })
        );
      })
      .catch((error) => {
        console.log("Error registration ", error);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
